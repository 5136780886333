import React, { useState } from "react"

import AccountDeletionFormStyles from "./account-deletion.module.css"
import FlexWrapper from "../../../shared/components/fields/flex-wrapper"
import IconAction from "../../../shared/components/universal/icon-action"
import Input from "../../../shared/components/fields/input"
import LinkButtonWrapper from "../universal/link-button-wrapper"
import Spacer from "../universal/spacer"
import Text from "../matrix/text"
import { toast } from "react-toastify"
import { useDimensions } from "../../../shared/hooks/use-dimensions"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

const AccountDeletionForm = () => {
    const { t } = useTranslation("translations")
    const [isSuccess, setIsSuccess] = useState(false)

    const { handleSubmit, control, reset } = useForm({
        mode: "onTouched",
        defaultValues: {
            firstname: "",
            lastname: "",
            email: "",
            customerNumber: "",
        },
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
    })

    const submit = async values => {
        const body = {
            name_first: values.firstname,
            name_last: values.lastname,
            mail_address: values.email,
            customer_number: +values.customerNumber,
        }

        try {
            const response = await fetch(process.env.API_URL_IO + "request", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })
            if (!response.ok) {
                throw new Error("Error")
            }
            setIsSuccess(true)
            reset()
        } catch (error) {
            toast.error(t("account_deletion_form_error"))
        }
    }

    const { width } = useDimensions()

    return (
        <div className={AccountDeletionFormStyles.wrapper}>
            {!isSuccess ? (
                <form onSubmit={handleSubmit(submit)} name="account" className={AccountDeletionFormStyles.form}>
                    <h3>{t("account_deletion_form_headline")}</h3>
                    <FlexWrapper childWidths={width > 767 ? [50, 50] : [100, 100]} wrap>
                        <Input
                            control={control}
                            rules={{ required: { value: true, message: t("form_error_required") } }}
                            type="text"
                            name="firstname"
                            label={t("account_deletion_firstname_label")}
                            autocomplete="given-name"
                            required
                        />
                        <Input
                            control={control}
                            rules={{ required: { value: true, message: t("form_error_required") } }}
                            type="text"
                            name="lastname"
                            label={t("account_deletion_lastname_label")}
                            autocomplete="family-name"
                            required
                        />
                    </FlexWrapper>
                    <FlexWrapper childWidths={width > 767 ? [50, 50] : [100, 100]} wrap>
                        <Input
                            control={control}
                            rules={{ required: { value: true, message: t("form_error_required") } }}
                            type="email"
                            name="email"
                            label={t("account_deletion_email_label")}
                            autocomplete="email"
                            required
                        />
                        <Input
                            control={control}
                            rules={{ required: { value: false } }}
                            type="number"
                            name="customerNumber"
                            label={t("account_deletion_customer_number_label")}
                        />
                    </FlexWrapper>
                    <Text source={`<small>${t("account_deletion_smallprint")}</small>`} />
                    <Text source={`<small>${t("account_deletion_required")}</small>`} />
                    <Spacer small />
                    <LinkButtonWrapper center>
                        <IconAction
                            title={t("account_deletion_submit")}
                            button
                            submit
                            variant={AccountDeletionFormStyles.button}
                        />
                    </LinkButtonWrapper>
                </form>
            ) : (
                <div className={AccountDeletionFormStyles.success}>
                    <div className={AccountDeletionFormStyles.circle}>
                        <i className="lavita-icon"></i>
                    </div>
                    <Text
                        source={t("account_deletion_form_success_text")}
                        align={{ desktop: "center", tablet: "center", mobile: "center" }}
                    />
                </div>
            )}
        </div>
    )
}

export default AccountDeletionForm
