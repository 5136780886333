import React, { useMemo } from "react"

import { Product } from "@lavita-io/international-sdk"
import { formatPrice } from "../../../shared/utils/format"
import { useLocalesState } from "../../../shared/context/locales"
import i18n from "../../../i18n/config"

export interface UpsellingItemBottlePriceProps {
    product?: Product
    bottlePrice?: boolean
}

const getBottlePrice = (product: Product, language: string, bottlePrice?: boolean) => {
    return (product.sku === "LV2START" || product.sku === "LVSTARTNA") && bottlePrice
        ? product.sku === "LV2START"
            ? i18n.t("upselling_price_per_bottle", {
                  price: formatPrice(
                      product.price.amount / (product.splitted?.parts || 1),
                      product.price.currency,
                      language
                  ),
              })
            : product.sku === "LVSTARTNA"
            ? i18n.t("upselling_price_per_bottle", {
                  price: formatPrice(product.price.amount / 3, product.price.currency, language),
              })
            : null
        : null
}

const UpsellingItemBottlePrice = ({ product, bottlePrice }: UpsellingItemBottlePriceProps) => {
    const { language } = useLocalesState()

    if (!product) return null

    const pricePerBottle = useMemo(
        () => getBottlePrice(product, language, bottlePrice),
        [product, bottlePrice, language]
    )
    return <p className="text-sm sm:text-base lg:text-md">{pricePerBottle}</p>
}

export default UpsellingItemBottlePrice
